@media (max-width:600px) {
    .main-menu
    {
      font-size: 1.5em;
    }
    #main-menu-desktop
    {
      display:none;
    }
    #main-menu-mobile
    {
      display:block;
    }
    #badges
    {
      width: 35vw;
    }
    body
    {
      font-size:0.8em;
    }
}
@media (min-width: 601px) and (max-width: 1500px)
 {
    .main-menu
    {
      font-size: 2em;
    }
    #main-menu-desktop
    {
      display:none;
    }
    #main-menu-mobile
    {
      display:block;
    }
    #badges
    {
      width: 25vw;
    }
    body
    {
      font-size:1em;
    }
}
@media (min-width: 1500px)
{
    .main-menu
    {
      font-size:2.5em;
    }
    #main-menu-desktop
    {
      display:block;
    }
    #main-menu-mobile
    {
      display:none;
    }
    #badges
    {
      width: 20vw;
    }
    body
    {
      font-size:1.5em;
    }
}

body
 {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url('images/background.jpg');*/
  background-color:black;
  width: 100vw;
  height:100vh;
  padding:0;
  margin:0;
  overflow-x: hidden;
}
#badges
{
  position:fixed;
  bottom:0;
  left:0;
}
.badge
{
  width: 40%;
  margin-left: 5%;
}
ul
{
  margin:0;
}
.fitted-img
{
  width: 60vw;
}
iframe
{
  width: 80vw;
  height:80vh;
}

header
{
    z-index: 5000000000000;
    position:fixed;
    color:black;
    text-decoration: none;
    font-family: 'Bebas Neue', cursive;
    top: 5vh;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    right:10vw;
}
header:hover
{
  -webkit-transform: translateX(-3%);
          transform: translateX(-3%);
}
header h2 a
{
  text-decoration: none;
  color:black;
}
header h2 a:hover
{
  text-decoration: none;
  color:rgba(15, 204, 15, 0.76);  
}
.main-menu
{
  margin-left: 20vw;
  margin-top:15vh;
}
.main-menu a
{
  text-decoration: none;
}

.header-warning
{
  background-color: orange;
  text-align: center;
  color:white;
  padding:1vw;
  position: fixed;
  z-index: 3000;
  top:0;
  width: 100vw;
}
h1
{
  /*text-shadow: 5px 5px yellow; */
  margin:0;
  padding:0.5vh;
  font-family: 'Bebas Neue', cursive;
}
.font-white
{
  color:white;
}
.font-green
{
  color:rgba(15, 204, 15, 0.76);
}
.swapper-title
{
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.swapper-title:hover
{
  -webkit-transform: translateX(2%);
          transform: translateX(2%);
}

iframe
{
  /*add box shadow*/
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  border-radius: 1em;
}

article
{
    position:relative;
    background-color: white;
}
.article-head
{
    font-family:Consolas,Monaco,'Andale Mono','Ubuntu Mono',monospace;
    text-align: center; 
}
code
{
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    display: block;
    line-height: 200%;
    padding:2vw;
    width: 36vw;
    text-align: center;
    margin-left:30vw;
    background-color: black;
    color:white;
}
form
{
    text-align: center;
    margin-top:20vh;
    font-family: 'Bebas Neue', cursive;
}


form h1
{
    color:rgba(15, 204, 15, 0.76);
    margin-bottom: 5vh;
}

form input
{
    width: 30vw;
    height:5vh;
    text-align: center;
    margin-bottom: 2vh;
    color: grey;
    font-size: 2.5em;
    padding:15px;
    font-family: 'Bebas Neue', cursive;
    border:none;
    border-radius: 1em;
}
form label
{
    color:rgba(15, 204, 15, 0.76);
    font-size:3em;
    font-family: 'Bebas Neue', cursive;
}
form textarea
{
    text-align: center;
    margin-bottom:1vh;
    width: 30vw;
    padding:15px;
    height:10vh;
    color:grey;
    font-size: 1.5em;
    border:none;
    font-family: 'Bebas Neue', cursive;
    border-radius: 1em;
}
form button
{
    border: none;
    background-color:rgba(15, 204, 15, 0.76);
    padding: 1.2vw;
    border-radius: 1em;
    color: white;
    font-size:2em;
    font-family: 'Bebas Neue', cursive;
}
.swapper-title
{
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.swapper-title:hover
{
  -webkit-transform: translateX(2%);
          transform: translateX(2%);
}

ul
{
    text-align: center;
    color: rgba(15, 204, 15, 0.76);
    margin:0;
    padding:0;
    margin-top:20vh;
    font-family: 'Bebas Neue', cursive;
}
#work-list
{
   padding-bottom: 5vh;
}
#font-white
{
    color:white;
}
#font-white:hover
{
    color:rgba(15, 204, 15, 0.76);
}
ul li
{
    list-style-type: none;
    margin-bottom: 5vh;
}
ul li a
{
    text-decoration: none;
    color: rgba(15, 204, 15, 0.76);
}
ul li h1
{
    font-size: 4em;
    color: white;
}
ul li h2
{
    font-size: 2em;
}
.swapper-title
{
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.swapper-title:hover
{
  -webkit-transform: translateX(2%);
          transform: translateX(2%);
}

@media (max-width:600px) { 
  article h1
  {
    font-size: 3em;
  }
}
@media (min-width: 601px) and (max-width: 900px)
{
  article h1
  {
    font-size: 5em;
  }
}
@media (min-width: 901px)
{
  article h1
  {
    font-size:7em;
  }
}
article h1
{
  color:rgba(15, 204, 15, 1);
}
article p
{
  color:black !important;
  /*Change the font below to a font that fits programming blogs*/
  font-family: none !important;
  border-radius: 1em;
  padding:1vw;
  /*Add box shadow to the code block*/
  box-shadow: 0 0 0.32em rgba(0, 0, 0, 0.5);
}
code
{
  border-radius: 1em;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5) !important;
}
article h2,h3,h4,h5,h6
{
  color:black;
}
article
{   
    /*Add box shadow*/
    box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5);
    background-color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'Bebas Neue', cursive;
    padding-top: 15vh;
    padding-bottom: 15vh;
}
article img
{
  width:30%;
}
article p
{
    font-family: 'Courier New', Courier, monospace;
    color:grey;
    margin-left: 20vw;
    margin-right: 20vw;
}
.swapper-title
{
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.swapper-title:hover
{
  -webkit-transform: translateX(2%);
          transform: translateX(2%);
}
