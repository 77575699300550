@media (max-width:600px) {
    .main-menu
    {
      font-size: 1.5em;
    }
    #main-menu-desktop
    {
      display:none;
    }
    #main-menu-mobile
    {
      display:block;
    }
    #badges
    {
      width: 35vw;
    }
    body
    {
      font-size:0.8em;
    }
}
@media (min-width: 601px) and (max-width: 1500px)
 {
    .main-menu
    {
      font-size: 2em;
    }
    #main-menu-desktop
    {
      display:none;
    }
    #main-menu-mobile
    {
      display:block;
    }
    #badges
    {
      width: 25vw;
    }
    body
    {
      font-size:1em;
    }
}
@media (min-width: 1500px)
{
    .main-menu
    {
      font-size:2.5em;
    }
    #main-menu-desktop
    {
      display:block;
    }
    #main-menu-mobile
    {
      display:none;
    }
    #badges
    {
      width: 20vw;
    }
    body
    {
      font-size:1.5em;
    }
}

body
 {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url('images/background.jpg');*/
  background-color:black;
  width: 100vw;
  height:100vh;
  padding:0;
  margin:0;
  overflow-x: hidden;
}
#badges
{
  position:fixed;
  bottom:0;
  left:0;
}
.badge
{
  width: 40%;
  margin-left: 5%;
}
ul
{
  margin:0;
}
.fitted-img
{
  width: 60vw;
}
iframe
{
  width: 80vw;
  height:80vh;
}

header
{
    z-index: 5000000000000;
    position:fixed;
    color:black;
    text-decoration: none;
    font-family: 'Bebas Neue', cursive;
    top: 5vh;
    transition: transform 0.5s;
    transform: translateX(0);
    right:10vw;
}
header:hover
{
  transform: translateX(-3%);
}
header h2 a
{
  text-decoration: none;
  color:black;
}
header h2 a:hover
{
  text-decoration: none;
  color:rgba(15, 204, 15, 0.76);  
}
.main-menu
{
  margin-left: 20vw;
  margin-top:15vh;
}
.main-menu a
{
  text-decoration: none;
}

.header-warning
{
  background-color: orange;
  text-align: center;
  color:white;
  padding:1vw;
  position: fixed;
  z-index: 3000;
  top:0;
  width: 100vw;
}
h1
{
  /*text-shadow: 5px 5px yellow; */
  margin:0;
  padding:0.5vh;
  font-family: 'Bebas Neue', cursive;
}
.font-white
{
  color:white;
}
.font-green
{
  color:rgba(15, 204, 15, 0.76);
}
.swapper-title
{
  transform: translateX(0);
  transition: transform 0.5s;
}
.swapper-title:hover
{
  transform: translateX(2%);
}

iframe
{
  /*add box shadow*/
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  border-radius: 1em;
}